
import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import { namespace } from "vuex-class";
import { AUTH_STORE_NAME } from "@/store/auth.store";
import PenaltyPaymentRepository from "@/api/repositories/PenaltyPaymentRepository";
import PaypalRepository from "@/api/repositories/PaypalRepository";
import PaypalPayment from "@/models/PaypalPayment";

const AuthStore = namespace(AUTH_STORE_NAME);

interface PaymentInformation {
  amount: number,
  company: string
}

@Component({
  components: {
    LogoComponent: () => import(
      /* webpackChunkName: "LogoComponent" */
      '@/components/Logo.component.vue'
    ),
  }
})
export default class PayPayPalView extends mixins(ErrorMessageHandlerMixin) {
  /**
   * Reset Token fetched by the URL Query
   * @private
   */
  private transactionId?: string;

  /**
   * id of the user account
   * @private
   */
  private accountId?: string;

  /**
   * Alert Message to Show
   * @private
   */
  private alertMessage: string = "";

  /**
   * State bool to show the Alert above the Form
   * @private
   */
  private showAlert: boolean = false;

  /**
   * Loading State Bool
   * @private
   */
  private isLoading: boolean = false;

  /**
   * Form Locked State Bool
   * @private
   */
  private isLocked: boolean = false;

  /**
   * flag that indicates if the verify process was completed successfully
   * @private
   */
  private hasPayed: boolean = false;

  /**
   * info to the payment (transactions and company names etc.)
   * @private
   */
  private paymentInfo: PaypalPayment | null = null;

  /**
   * Is Mobile Vuetify Breakpoint Triggered
   */
  public get isMobile() {
    return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
  }

  private paymentLink: string = '';

  /**
   * Created Hook
   * Fetches the Reset Token from the URL Query
   */
  async created() {
    // gets account id and transaction id from the url
    this.accountId = this.$route.params.accountId;
    const transactionId = this.$route.query.token;

    // Token is not available through a query param
    // show an error describing that issue
    if (!transactionId) {
      this.isLocked = true;
      this.alertMessage = this.$t("VERIFY_ACCOUNT.EXPIRED_TOKEN").toString();
      this.showAlert = true;
      return;
    }

    // Parse given token as String
    this.transactionId = transactionId as string;

    try {
      this.isLoading = true;
      const response = await PaypalRepository.getPaymentById(this.transactionId, this.accountId);
      this.paymentInfo = PaypalPayment.parseFromObject(response.data);

      await this.payWithPaypal();
    } catch(e) {
      this.alertMessage = this.$t('PAY_WITH_PAYPAL.PAY.NO_TRANSACTIONS').toString();
      this.showAlert = true;
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Reset Password Method
   * This will Reset the Password via an API call
   * @private
   */
  private async payWithPaypal() {
    // Disable Alert Message
    this.showAlert = false;

    // Try to set the new Password
    try {
      if(!this.transactionId || !this.accountId) {
        this.showAlert = true;
        return;
      }

      this.isLoading = true;
      const response = await PenaltyPaymentRepository.settlePayment(this.transactionId);
      this.paymentLink = response.data['approvalLink'];
    } catch (e) {
      this.isLocked = true;
      this.$handleError(e, () => {
        switch (e.status) {
          case 400:
            this.alertMessage = this.$t("PAY_WITH_PAYPAL.PAY.PENALTY").toString();
            break;
            // No Account or Token Found
          case 404:
            this.alertMessage = this.$t("PAY_WITH_PAYPAL.PAY.NO_TRANSACTIONS").toString();
            break;
          case 422:
            this.alertMessage = this.$t("PAY_WITH_PAYPAL.PAY.NO_ACTIVE_PENALTIES").toString();
            break;
          case 423:
            this.alertMessage = this.$t("PAY_WITH_PAYPAL.PAY.PAYPAL_ERROR").toString();
            break;
          default:
            this.alertMessage = this.$t("GENERAL.NOTIFICATIONS.GENERAL_ERROR.TEXT").toString();
        }

        // Show Alert Message
        this.showAlert = true;
      });
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * On Logout handler
   */
  private async routeToLogin() {
    console.log('ROUTE');

    // redirects to login view
    // if(this.$route.name !== ROUTE_LOGIN) await this.$router.replace({name: ROUTE_LOGIN});
  }
}
