import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";
import {AUTH_CLIENT} from "@/api/misc/AuthClient";

/**
 * repository that provides all the api requests that deal with PayPal payments
 */
export default class PaypalRepository {
    private static baseUrl: string = 'api/paypal';

    /**
     * gets the payment by the id
     */
    public static getPaymentById(transactionId: string, userId: string): Promise<AxiosResponse> {
        return AUTH_CLIENT.get(`${this.baseUrl}/payment/${userId}/${transactionId}`);
    }

    /**
     * gets the status of the passed payment / transaction
     * @param transactionId
     * @param userId
     */
    public static getPaymentStatus(transactionId: string, userId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`${this.baseUrl}/payment/${userId}/${transactionId}/status`);
    }
}
