import Parseable from "@/misc/Parseable";
import {PaymentType} from "@/enum/PaymentType.enum";
import Company from "@/models/Company";

export default class PaypalPayment extends Parseable {
	public paymentId!: string;
	public total!: number;
	public transactions!: {
		amount: number,
		company: Company
	}[];

	parseToObject(): any {
		return {...this};
	}

	/**
	 * parses an object to a news object
	 * @param object
	 */
	public static parseFromObject(object: any): PaypalPayment {
		const paypalPayment = new PaypalPayment();
		Object.assign(paypalPayment, object);
		return paypalPayment;
	}
}