import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";
import {AUTH_CLIENT} from "@/api/misc/AuthClient";

export default class PenaltyPaymentRepository {
    private static baseUrl: string = 'api/penalty';

    /**
     * settles a payment, returns the paypal approval link and amount
     */
    public static settlePayment(transactionId: string): Promise<AxiosResponse> {
        return AUTH_CLIENT.post(`${this.baseUrl}/settle/${transactionId}`);
    }
}
